//*----------  COMPONENTS  ----------*/
import DashBoard     from '@/components/DashBoard/DashBoard.vue'
import DataGrid      from '@/components/DataGrid/DataGridSimple/DataGridSimple.vue'
    // ? Propios
import TabService    from './componentes/TabsItems/tabServicios.vue'
import DialogForm    from '@/components/Dialogs/DialogForm/DialogForm.vue'
import service       from '@/services/soporte'
import {
    mapActions
} from 'vuex';
export default {
    name: "Servicio",

    components: {
        DashBoard,
        DataGrid,
        TabService,
        DialogForm
    },
    props: {
        dataConcepto: Object
    },
    data: () => ({
        new: true,
        lazy   : false,

        configGrid: {
            summary: true,
            paging: {
                properties: {
                    "page-size": 25
                }
            },
            export: {
                export: false
            },
        },
        mainTab: null,
       
        title: "Nuevo",
        valid: false,
        users: [],
        user: undefined,
        actions: [],
        disabled: false,
        rules: {
            users       : [ v => !!v || 'Seleccione un titular' ],
            servicio    : [ v => !!v || 'Ingrese nombre del servicio' ],
            descripcion : [ v => !!v || 'Ingrese descripción' ]
        },
        // ********************************
        idCT: -1,
        servicio: undefined,
        descripcion: undefined,
        fechaU: null,
        fullWidth: true,
        dataSource: [],

        dtEdit: null,
        // ?? mostart datos eliminados
        dataFunction: [{
            format: (data) => {
                let res = data.val;
                return res;
            },
            class: (data) => {
                let res = data.data.data.activo == 1 ? "" : "delete"
                return res;
            }
        }],
        oldObject: {},
        id_baseCtEstatus: 1
    }),
    computed: {},
    methods: {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions("Servicio", ["setDataSource"]),
        // ??? Eventos click de los botones de menu, se pueden reconocer por el idBtn o por siu diRlCA
        loadEvent({
            idRlCA,
            idBtn
        }) {           
            switch (idBtn) {
                case "btnNuevo":
                    this.new = true;
                    this.setDialog({
                        key    : "dlgServicio",
                        status :true
                    });
                    break;
            }
        },
        /* 
        *   Las acciones que se tienen en la tabla principal, se recibe un objeto
        *   event       =>  Datos del renglon donde se puede hacer la actualizacion de los datos que se tienen en ella {event, component, element, row, column} la propiedad row contienen los valores que se pueden cambiar en row.data
        *   dataAction  =>  Objeto que contiene los datos del permiso asignado{ idRlCA, accion, urlIcon, idBtn}, para reconocer el evento se puede reconocer por el idRlCA o por elidBtn
        ??? Estas propiedades se construyen en DistribucionGen.js
        */
        eventActionTable({
            event,
            dataAction
        }) {
            switch (dataAction.idBtn) {
                case "btnEdicion":
                    this.edit(event);
                    break; // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
                case "btnEliminar":
                    this.eliminar(event);
                    break; // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
                case "btnActive":
                    this.activar(event);
                    break; // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
            }
        },
        // ?? Funciones para la tabla principal
        edit(data) {
            let dataRow = data.row.data;
            if (dataRow.activo != 1) {
                this.$Msg.error("Solo se puede editar un servicio activo.")
                return;
            }
            this.idCT = dataRow.id;
            this.user = dataRow.id_adminCtUser;
            this.servicio = dataRow.servicio;
            this.descripcion = dataRow.descripcion;
            this.fechaU = dataRow.fechaU;
            this.disabled = true;
            this.oldObject = this.buildObjec();
            this.new = false;
            this.setDialog({
                key    : "dlgServicio",
                status :true
            });
        },
        eliminar(data) {
            let dataRow = data.row.data;
            let arrInfo = [];
            let ctServicio = [];
            this.$Msg.confirma(`¿Desea eliminar el servicio ${dataRow.servicio}?`).then(result => {
                if (result.value) {
                    ctServicio.push({
                        id_soporteCtServicio: dataRow.id,
                        activo: -1,
                        updated_at: dataRow.fechaU
                    });
                    console.log(dataRow)
                    arrInfo.push((this.$JsTable.getFormat("soporteCtServicio", "Update", ctServicio, ["id_soporteCtServicio"])));
                    service.SopU_eliminarServicio(arrInfo).then(result => {
                        if (this.$Msg.fn({
                                data: result,
                                message: "Registro eliminado correctamente"
                            }))
                            this.cargarData();
                    })
                }
            });
        },
        activar(data) {
            let dataRow = data.row.data;
            let arrInfo = [];
            let ctServicio = [];
            this.$Msg.confirma(`¿Desea ${dataRow.activo == 1 ? "desactivar" : "activar"} el servicio ${dataRow.servicio}?`).then(result => {
                if (result.value) {
                    ctServicio.push({
                        id_soporteCtServicio: dataRow.id,
                        activo: dataRow.activo == 1 ? 0 : 1,
                        updated_at: dataRow.fechaU
                    });
                    arrInfo.push((this.$JsTable.getFormat("soporteCtServicio", "Update", ctServicio, ["id_soporteCtServicio"])));
                    service.SopU_activarServicio(arrInfo).then(result => {
                        if (this.$Msg.fn({
                                data: result,
                                message: `Registro ${dataRow.activo == 1 ? "desactivado" : "activado"} correctamente`
                            }))
                            this.cargarData();
                    })
                }
            });
        },
        // ?? Funcion para la tabla de detallado
        eventClose() {
            this.reset();
            this.setDialog({
                key    : "dlgServicio",
                status :false
            });
        },
        eventGen() {
            try {
                if (this.validate()) {
                    if (this.new) {
                        let servicio = this.buildObjec();
                        let arrInfo = [];
                        arrInfo.push(this.$JsTable.getFormat("soporteCtServicio", "Insert", [servicio], ["id_soporteCtServicio"]));
                        service.SopC_servicio(arrInfo).then(result => {
                            if (this.$Msg.fn({
                                    data: result,
                                    message: "Servicio creado correctamente"
                                })) {
                                this.eventClose();
                                this.cargarData();
                            }
                        })
                    } else {
                        let servicio = this.buildObjec();
                        let arrInfo = [];
                        let ctServicio = this.$fn.getDiffObj([this.oldObject], [servicio], ["id_soporteCtServicio"])
                        if (ctServicio == 0)
                            throw "No se realizó ningún cambio"
                        ctServicio[0].updated_at = this.fechaU;
                        console.log(ctServicio)
                        arrInfo.push(this.$JsTable.getFormat("soporteCtServicio", "Update", ctServicio, ["id_soporteCtServicio"]));
                        service.SopU_servicio(arrInfo).then(result => {
                            if (this.$Msg.fn({
                                    data: result,
                                    message: "Servicio actualizado correctamente"
                                })) {
                                this.eventClose();
                                this.cargarData();
                            }
                        })
                    }
                }
            } catch (error) {
                this.$Msg.error(error)
            }
        },
        buildObjec() {
            let obj = {
                id_soporteCtServicio: this.idCT,
                id_adminCtUser: this.user,
                servicio: this.servicio,
                descripcion: this.descripcion,
                activo: 1
            }
            return obj;
        },
        validate() {
            return this.$refs.form.validate();
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        cargarData() {
            service.SopR_dataSourceServicios().then(result => {
                this.setDataSource(this.$JsTable.toObj(result.data))
            })
        },
    },created() {
        service.SopR_dataUsers().then(result => {
            this.users = this.$JsTable.toObj(result.data);
        })
    }
}
